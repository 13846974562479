import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-page-title',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit {

  @Input() breadcrumbItems;
  @Input() title: string;
  @Input() showTitle: boolean = false;

  constructor( 
    private router: Router, 
  ) { }

  navigate(link:string){
    this.router.navigate([link])
  }

  ngOnInit() {
  }

}
