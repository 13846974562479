import { MenuItem } from './menu.model';

export const getMenu = (projectId) => {
    const menu: MenuItem[] = [
        {
            label: 'Account & Security',
            isTitle: true,
        },
        {
            label:'Profile',
            icon:'bx-user-circle',
            link: 'settings/profile'
        },
        {
            label:'Update Password',
            icon:'bx-key',
            link: 'settings/update-password'
        },
        {
            label: 'Entities',
            isTitle: true,
        },
        {
            label:'Companies',
            icon:'bx-building',
            link: 'settings/companies'
        },
        {
            label:'Billing Accounts',
            icon:'bx-credit-card',
            link: 'settings'
        }, 
    ];

    return menu;
}

export let MENU: MenuItem[] = [
    {
        label: 'Account & Security',
        isTitle: true,
    },
    {
        label:'Profile',
        icon:'bx-user-circle',
        link: 'settings/profile'
    },
    {
        label:'Update Password',
        icon:'bx-key',
        link: 'settings/update-password'
    },
    {
        label: 'Entities',
        isTitle: true,
    },
    {
        label:'Companies',
        icon:'bx-building',
        link: 'settings/companies'
    },
    {
        label:'Billing Accounts',
        icon:'bx-credit-card',
        link: 'settings'
    }, 
];

